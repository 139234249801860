import { userConstants } from "../_constants";
import { alertActions } from "./alert.actions";
import {
  APIcallFunction,
  headerForPublicAPI,
  logoutFunction,
  headerForPrivateAPI,
  APIcallFunctionForLogin,
  APIcallFunctionForImage,
  headerForPrivateMediaAPI,
  authHeader,
} from "../_helpers";
import { reportActions } from "./report.actions";
export const userActions = {
  login,
  getProfile,
  getAdminProfile,
  getProfileforAdmin,
  registerUserByAdmin,
  getUserList,
  updateUser,
  updateUserStatus,
  deleteUser,
  logout,
  uploadImage,
  clearImage,
  getClientBankDetail,

  getBetsListByUserIdForAdmin,
  transactionHistory,
  getLoginHistoryByUserId,

  getTotalCount,
  getUserOfMaster,
  blockedGames,
  changePasswordforAdmin,
  changePassword,
  blockeNumbercreate,
  doubleAmount,

  DeleteBet,
  getreference,
};

function DeleteBet(data, reff) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/DeleteBet",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
        dispatch(alertActions.success("Deleted"));
        dispatch(reportActions.getBetsListForAdmin(reff));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.DELETE_BETS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.DELETE_BETS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.DELETE_BETS_FAILURE, error };
  }
}
function doubleAmount(data, userId) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/doubleAmount",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
        dispatch(alertActions.success("Double Amount Updated"));
        dispatch(userActions.getProfileforAdmin({ userId: data?.userId }));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.DOUBLE_AMOUNT_S_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.DOUBLE_AMOUNT_S_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.DOUBLE_AMOUNT_S_FAILURE, error };
  }
}

function getreference(data, userId) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getreference",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.CREDIT_REF_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.CREDIT_REF_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CREDIT_REF_FAILURE, error };
  }
}
function blockeNumbercreate(data, userId) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/blockeNumbercreate",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
        dispatch(alertActions.success("Blocked Number Updated"));
        dispatch(userActions.getProfileforAdmin({ userId: data?.userId }));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.BLOCK_NUMBERS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.BLOCK_NUMBERS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.BLOCK_NUMBERS_FAILURE, error };
  }
}
function changePasswordforAdmin(data,setFieldsUser) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/changePasswordforAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
        dispatch(alertActions.success("Password Updated"));
        setFieldsUser((prevState) => ({ ...prevState, passwords: "" }));

      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.PASSWORD_UPDATED_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.PASSWORD_UPDATED_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.PASSWORD_UPDATED_FAILURE, error };
  }
}
function changePassword(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/changePassword",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
        dispatch(alertActions.success("Password Updated"));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.PASSWORD_UP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.PASSWORD_UP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.PASSWORD_UP_FAILURE, error };
  }
}
function blockedGames(data, userId) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/blockedGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Updated Sucessfully"));
        dispatch(userActions.getAdminProfile());

        dispatch(userActions.getProfileforAdmin({ userId: data?.clientId }));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.BLOCKED_GAME_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.BLOCKED_GAME_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.BLOCKED_GAME_FAILURE, error };
  }
}
function getUserOfMaster(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getUserOfMaster",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        console.log(user);
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_USER_OF_MASTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_USER_OF_MASTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_OF_MASTER_FAILURE, error };
  }
}
function getTotalCount(data) {
  console.log("headerForPrivateAPIheaderForPrivateAPI", headerForPrivateAPI);
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getTotalCount",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // console.log(user)
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_TOTAL_COUNT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_TOTAL_COUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_TOTAL_COUNT_FAILURE, error };
  }
}

function getLoginHistoryByUserId(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getLoginHistoryByUserId",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_LOGIN_HISTORY_ID_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_LOGIN_HISTORY_ID_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_LOGIN_HISTORY_ID_FAILURE, error };
  }
}

function transactionHistory(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/transactionHistoryForAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_TXN_LIST_USERID_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_TXN_LIST_USERID_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_TXN_LIST_USERID_FAILURE, error };
  }
}

function getBetsListByUserIdForAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getBetsListByUserIdForAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_BET_LIST_USERID_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_BET_LIST_USERID_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_BET_LIST_USERID_FAILURE, error };
  }
}

function clearImage() {
  return {
    type: userConstants.CLEAR_IMAGE,
  };
}

function getClientBankDetail(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getClientBankDetail",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_BANK_DETAILS_BY_ADMIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_BANK_DETAILS_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_BANK_DETAILS_BY_ADMIN_FAILURE, error };
  }
}

function uploadImage(data) {
  return (dispatch) => {
    dispatch(request(data));

    const formData = new FormData();
    formData.append("image", data.target.files[0]);

    const credentials = {
      body: formData,
      endPoint: "/upload",
    };
    APIcallFunctionForImage(credentials).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: userConstants.UPOLAD_IMAGE_REQUEST, data };
  }

  function success(response) {
    return { type: userConstants.UPOLAD_IMAGE_SUCCESS, response };
  }

  function failure(error) {
    return { type: userConstants.UPOLAD_IMAGE_FAILURE, error };
  }
}

function login(data, navigate) {
  const credentials = {
    header: headerForPublicAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/adminLogin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        // window.sessionStorage.setItem("adminuser", JSON.stringify(user.data));
        localStorage.setItem("adminuser", JSON.stringify(user.data));
        sessionStorage.setItem("adminuser", JSON.stringify(user.data));

        dispatch(success(user));
        dispatch(alertActions.success("Login Successfully"));
        if (navigate) {
          navigate("/app/dashboard");
          window.location.reload();
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function getProfile(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getProfile",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_PROFILE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_PROFILE_FAILURE, error };
  }
}
function getAdminProfile(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getAdminProfile",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_ADMIN_PROFILE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_ADMIN_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_ADMIN_PROFILE_FAILURE, error };
  }
}
function getProfileforAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getProfileforAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_USER_BY_USERID_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_USER_BY_USERID_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_BY_USERID_FAILURE, error };
  }
}
function getUserList(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getUserList",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.GET_USER_LIST_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_USER_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_LIST_FAILURE, error };
  }
}

function updateUser(
  data
  // , userPageRefresh
) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updateUser",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getUserList(userPageRefresh));
        dispatch(alertActions.success("User Update Successfully"));
        // setUpdateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.UPDATE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}

function registerUserByAdmin(data, userPageRefresh) {
  console.log(userPageRefresh);

  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/registerUserByAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(reportActions.getLedger());
        dispatch(userActions.getUserList(userPageRefresh));

        dispatch(alertActions.success("User Create Successfully"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.USER_CREATE_BY_ADMIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.USER_CREATE_BY_ADMIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.USER_CREATE_BY_ADMIN_FAILURE, error };
  }
}

function updateUserStatus(data, userPageRefresh, id) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updateUserStatus",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(reportActions.getLedger());
        if (id) {
          dispatch(userActions.getProfileforAdmin({ userId: id }));
        }
        if (userPageRefresh) {
          dispatch(getUserList(userPageRefresh));
        }
        dispatch(alertActions.success("User Status Update Successfully"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.UPDATE_USER_STATUS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_STATUS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_STATUS_FAILURE, error };
  }
}

function deleteUser(data, userPageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/deleteUser",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getUserList(userPageRefresh));
        dispatch(alertActions.success("User Delete Successfully"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(user) {
    return { type: userConstants.DELETE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.DELETE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.DELETE_USER_FAILURE, error };
  }
}

function logout() {
  logoutFunction();
  return { type: userConstants.LOGOUT };
}
