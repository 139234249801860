import {
  appSettingConstants
} from '../_constants';

export default function appSettings(state = {}, action) {
  switch (action.type) {

    case appSettingConstants.CREATE_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.CREATE_APP_SETTING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case appSettingConstants.CREATE_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appSettingConstants.GET_APP_SETTING_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.GET_APP_SETTING_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        appSettingList: action.appSetting.data?.list,
        total: action.appSetting.data?.total,
        loading: false,
      };
    case appSettingConstants.GET_APP_SETTING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appSettingConstants.UPDATE_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.UPDATE_APP_SETTING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case appSettingConstants.UPDATE_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appSettingConstants.UPDATE_APP_SETTING_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.UPDATE_APP_SETTING_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case appSettingConstants.UPDATE_APP_SETTING_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case appSettingConstants.DELETE_APP_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case appSettingConstants.DELETE_APP_SETTING_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case appSettingConstants.DELETE_APP_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}