import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoChevronBackCircle } from "react-icons/io5";
import {
  bankActions,
  gamesActions,
  marketActions,
  userActions,
  walletActions,
} from "../../_actions";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { FiEdit } from "react-icons/fi";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  let selector = useSelector((state) => state);
  const { paymentList, totalPayment } = useSelector((state) => state.wallet);
  const { userDetailsById } = useSelector((state) => state.users);
  const [rowData, setrowData] = useState({});
  const [sortBy, setsortBy] = useState("asc");
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [viewModal, setviewModal] = useState(false);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [fieldsBank, setFieldsBank] = useState({});
  const [errorsBank, setErrorsBank] = useState({});
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUser, setFieldsUser] = useState({});
  const [errorsUser, setErrorsUser] = useState({});
  const [bankDetailsOfUser, setbankDetailsOfUser] = useState({});
  const [fromDate2, setfromDate2] = useState("");
  const [keyWord2, setkeyWord2] = useState("");
  const [sortBy2, setsortBy2] = useState("asc");
  const [size, setSize] = useState(10);
  const [size2, setSize2] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [toDate2, settoDate2] = useState("");
  const [offset2, setOffset2] = useState(0);
  const [pageNo2, setPageNo2] = useState(1);
  const [adminBlockedNumber, setadminBlockedNumber] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  let { bank, users } = selector ? selector : {};
  let { bankDetailsByAdmin, loading } = bank ? bank : {};
  let [sessionData, setsessionData] = useState({});
  let { markets } = selector ? selector : {};
  let { allMarket } = markets ? markets : {};

  // Function to retrieve session data
  const getSessionData = async () => {
    const sessiondata = await JSON.parse(sessionStorage.getItem("adminuser"));
    setsessionData(sessiondata);
    return { sessiondata };
  };

  // console.log(sessionData)

  useEffect(() => {
    getSessionData();

    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(marketActions.getAllMarket());
    dispatch(userActions.getProfileforAdmin({ userId: userId }));
    dispatch(userActions.getAdminProfile());

    let paymentListReq = {
      type: "DEPOSIT",
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };

    dispatch(walletActions.getPaymentListForAdmin(paymentListReq));

    dispatch(bankActions.getClientBankDetail({ userId: userId }));

    dispatch(userActions.transactionHistory(myBetReq2));
  }, [userId, size2]);

  const { gamesList, gamesTotal } = useSelector((state) => state.games);
  useEffect(() => {
    console.log("userDetailsByIduserDetailsById", userDetailsById);
    if (userDetailsById) {
      setFieldsUser(userDetailsById);
    }
    if (bankDetailsByAdmin) {
      setbankDetailsOfUser(bankDetailsByAdmin);
    }

    const blockedGames = selector?.users?.userDetailsById?.blockedGames || [];
    const selectedData = gamesList?.filter((game) =>
      blockedGames.includes(game._id)
    );
    let allMarketData = gamesList;

    // Uncomment and use these if needed:
    // setOptions(allMarketData);
    if (sessionData.roleId !== 0) {
      setoptions(selectedData);
    } else {
      setoptions(allMarketData);
    }
    setMarketSelected(selectedData);
  }, [userDetailsById, bankDetailsByAdmin, gamesList]);

  const inputChangeUser = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUser((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUser((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateUserSubmit = (e) => {
    if (handleValidationUserUpdate()) {
      const { userName, mobNo, email, _id, share, comm } = fieldsUser;
      dispatch(
        userActions.updateUser({
          userName: userName && userName.trim(),
          mobNo: mobNo && mobNo.trim(),
          // email: email && email.trim(),
          share: share,
          comm: comm,
          userId: _id,
        })
      );
    }
  };
  const updateUserSubmitPassword = (e) => {
    // if (handleValidationUserUpdatess()) {
    const { userName, mobNo, passwords, email, _id, share, comm } = fieldsUser;
    dispatch(
      userActions.changePasswordforAdmin({
        // userName: userName && userName.trim(), mobNo: mobNo && mobNo.trim(),
        password: passwords && passwords.trim(),
        // share:share,
        // comm:comm,
        userId: _id,
      },
      setFieldsUser
    )
    );
  
    // }
  };

  const handleValidationUserUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = "Enter userName";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = "Enter mobile Number";
    }

    setErrorsUser(errors);
    console.log("errorsBank!!!!!!!!!!!!!", errorsBank);
    return formIsValid;
  };

  const inputChangeBank = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setbankDetailsOfUser((prevState) => ({ ...prevState, [name]: value }));
    setErrorsBank((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateBankDetailsSubmit = (e) => {
    if (handleValidationBankUpdate()) {
      const { accountNo, name, ifsc, bankName, upiId } = bankDetailsOfUser;

      dispatch(
        walletActions.updateUserBankDetailByAdmin({
          userId: userId,
          accountNo: accountNo && accountNo,
          name: name && name.trim(),
          ifsc: ifsc && ifsc.trim(),
          bankName: bankName && bankName.trim(),
          upiId: upiId && upiId.trim(),
        })
      );
    }
  };

  const handleValidationBankUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!bankDetailsOfUser.accountNo || bankDetailsOfUser.accountNo === "") {
      formIsValid = false;
      errors.accountNo = "Enter accountNo";
    }

    if (!bankDetailsOfUser.name || bankDetailsOfUser.name.trim() === "") {
      formIsValid = false;
      errors.name = "Enter name";
    }

    if (!bankDetailsOfUser.ifsc || bankDetailsOfUser.ifsc.trim() === "") {
      formIsValid = false;
      errors.ifsc = "Enter ifsc";
    }

    if (
      !bankDetailsOfUser.bankName ||
      bankDetailsOfUser.bankName.trim() === ""
    ) {
      formIsValid = false;
      errors.bankName = "Enter bankName";
    }

    if (!bankDetailsOfUser.upiId || bankDetailsOfUser.upiId.trim() === "") {
      formIsValid = false;
      errors.upiId = "Enter upiId";
    }

    setErrorsBank(errors);

    return formIsValid;
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit2 = (e, data) => {
    // console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit3 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit4 = (e, data) => {
    console.log(e);
    setrowData({ ...e, key: data });
    setviewModal(true);
  };

  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };

  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      let obj = {
        userId: rowData._id,
        amount: rowData?.amount || 0,
      };

      // console.log(rowData?.key === "Withdraw Fund");
      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj, userId));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj,undefined,userId));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj,undefined ,userId));
      }

      setrowData({});
      setviewModal(false);
    }


  };

  const handleViewHideModal = () => {
    setrowData({});
    setviewModal(false);
    setFieldsGame({});
    setErrorsGame({});
    // setFieldsUser({});
    setErrorsUser({});
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };
  const handlePageClick2 = (data) => {
    let offset = Math.ceil(data.selected * size);
    setCurrentPage2(data.selected);
    setOffset2(offset);
    setPageNo2(data.selected + 1);
    let myBetReq = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy,
      pageNo: data.selected + 1,
      size: size,
      userId: userId,
    };
    dispatch(userActions.transactionHistory(myBetReq));
  };

  useEffect(() => {
    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };
    dispatch(userActions.transactionHistory(myBetReq2));
  }, [keyWord2, size2]);

  const handleInputSize2 = (e) => {
    const newSize = e.target.value;
    setPageNo2(1);
    setOffset2(0);
    setCurrentPage2(0);
    setSize2(newSize);
    setSize(newSize);
  };
  let handleFilter2 = () => {
    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };
    dispatch(userActions.transactionHistory(myBetReq2));
  };
  //----------------Multi select--------
  let [options, setoptions] = useState([]);
  // const { gamesList, gamesTotal } = useSelector(state => state.games);
  useEffect(() => {
    // Destructure with optional chaining to avoid undefined errors
    const blockgamIds =
      users?.adminProfileDetails?.adminData?.blockedGames?.flatMap(
        (game) => game?.games
      ) || [];

    const gamesLists =
      gamesList?.filter((e) => !blockgamIds.includes(e._id)) || [];
    const allMarketdata = gamesList || [];

    if (sessionData?.roleId === 0) {
      setoptions(allMarketdata);
    } else {
      setoptions(gamesLists);
    }
  }, [users, gamesList, sessionData]);

  const onSelect = (selectedList, selectedItem) => {
    let data = selectedList.map((e) => ({ name: e.name, id: e._id || e.id }));
    setMarketSelected(data);
    // setErrorsGame(prevState => ({ ...prevState, selectedmarketData: "" }));
  };

  const onRemove = (selectedList, removedItem) => {
    let filtervalue =
      selectedList && selectedList.filter((item) => item.id !== removedItem.id);
    setMarketSelected(filtervalue);
  };
  let BlockGame = () => {
    let arrayData =
      marketSelected &&
      marketSelected.length > 0 &&
      marketSelected.map((e) => e.id);
    dispatch(
      userActions.blockedGames({ gameId: arrayData || [], clientId: userId })
    );
  };

  const [selectMarket, setselectMarket] = useState({});
  const [selectMarketOption, setselectMarketOption] = useState([]);
  const [numberSelected, setnumberSelected] = useState([]);

  let handleSlectMarket = (event) => {
    setnumberSelected({});
    setselectMarket(event.target.value);
    let Options =
      allMarket &&
      allMarket.length > 0 &&
      allMarket.filter((e) => e._id == event.target.value);

    let arryDatas = Options && Options[0] && Options[0]?.numList.flat(Infinity);
    let Optionss =
      arryDatas &&
      arryDatas.length > 0 &&
      arryDatas.map((e) => ({ name: e, value: e }));
    console.log("OptionssOptionss", Optionss);

    const output = users?.adminProfileDetails && users?.adminProfileDetails?.adminData && users?.adminProfileDetails?.adminData?.blockedNumbers
      .filter((e) => e?.marketId === event.target.value) // Filter based on marketId
      .map((e) => ({
        numbers: e.numbers.flatMap((parent) => parent.numbers), // Flatten all numbers from parents
      }));

    // console.log("output",output)

    if (output && output.length > 0) {
      let data =
        Optionss &&
        Optionss.length > 0 &&
        Optionss.filter((e) => !output[0]?.numbers?.includes(e.value?.toString()));
      setselectMarketOption(data);
      console.log(data);
    } else {
      setselectMarketOption(Optionss);
    }
  };
  const onSelect2 = (selectedList, selectedItem) => {
    setnumberSelected(selectedList);
    // setErrorsGame(prevState => ({ ...prevState, selectedmarketData: "" }));
  };
  const BlockNumber = () => {
    // marketId, userId, numbers
    console.log("numberSelected", numberSelected);
    if (selectMarket && selectMarket.length == 0) {
      return;
    }
    // console.log(selectMarket);z
    let Obj = {
      marketId: selectMarket,
      numbers:
        (numberSelected &&
          numberSelected.length > 0 &&
          numberSelected.map((e) => e.value)) ||
        [],
      userId: userId,
    };
    dispatch(userActions.blockeNumbercreate(Obj));
  };

  //----------------Multi select--------       onSelect={onSelectMarket}
  // onRemove={onRemoveMarket}
  // let {markets} = selector?selector:{};
  // let {allMarket} = markets?markets:{};

  const handledoubleAmount = (e, data) => {
    e.preventDefault();
    dispatch(userActions.doubleAmount({ userId: data?._id }));
  };

  let { tnxList, tnxTotal } = users ? users : {};
  console.log("userDetailsById", fieldsUser);

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className=" p-3">
                    <IoChevronBackCircle
                      className="text-3xl"
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-3">
                        <div className="w-full col-span-1 bg-white rounded-xl border shadow-md">
                          <div className="bg-blue-600/20 rounded-t-xl p-4 flex justify-between items-center">
                            <div className="flex flex-col justify-start items-start pb-8">
                              <span className="text-[#7D1970] font-semibold capitalize">
                                {userDetailsById?.userName || ""}
                              </span>
                              <span className="text-[#7D1970]">
                                Admin Dashboard
                              </span>
                            </div>
                            <div className="flex flex-col justify-center items-end space-y-1 text-sm">
                              {/* <span className='flex text-black/70 space-x-1'><span>Active:</span>{userDetailsById?.isDisable ? <span className='bg-red-500 text-white font-semibold p-1 rounded'>No</span> : <span className='bg-green-500 text-white font-semibold p-1 rounded'>Yes</span>}</span> */}
                              {/* <span className='flex text-black/70 space-x-1'><span>TP:</span><span className='bg-green-500 text-white font-semibold p-1 rounded'>No</span></span> */}
                              {/* <span className='bg-red-500 text-white font-semibold p-1 rounded'>DELETE</span> */}
                              {/* <span className='bg-green-600 text-white font-semibold p-1 rounded'>26 day ago</span> */}
                            </div>
                          </div>
                          {/* <div className='flex justify-center items-center p-8'>
                            <span className='font-semibold text-gray-700'>Security PIN</span>
                          </div> */}
                          <div className="w-full space-y-4 p-5 border-t">
                            <div className="w-full flex flex-col justify-start">
                              <span className="text-black/70">
                                Available Balance
                              </span>
                              <span className="text-black/70 font-semibold">
                                {userDetailsById?.wallet || 0}
                              </span>
                            </div>
                            <div className="w-full flex justify-between items-center space-x-5">
                              <button
                                className="w-full flex justify-center px-4 py-2 text-sm font-semibold text-white capitalize  transition duration-150 ease-in-out bg-green-500 border border-green-500 rounded-full focus:outline-none"
                                type="button"
                                onClick={() =>
                                  createGameSubmit2(userDetailsById, "Add Fund")
                                }
                              >
                                Add Fund
                              </button>
                              <button
                                className="w-full flex justify-center px-4 py-2 text-sm font-semibold text-white capitalize  transition duration-150 ease-in-out bg-red-500 border border-red-500 rounded-full focus:outline-none"
                                type="button"
                                onClick={() =>
                                  createGameSubmit3(
                                    userDetailsById,
                                    "Withdraw Fund"
                                  )
                                }
                              >
                                Withdraw Fund
                              </button>
                            </div>
                            {/* <button
                              className="w-full flex justify-center px-4 py-2 text-sm font-semibold text-white capitalize  transition duration-150 ease-in-out bg-[black] border border-[#3E44D5] rounded-full focus:outline-none"
                              type="button"
                              onClick={() =>
                                createGameSubmit4(
                                  userDetailsById,
                                  "Credit Reference"
                                )
                              }
                            >
                              Credit Reference
                            </button> */}
                          </div>
                        </div>
                        {/* <div className="w-full col-span-2 p-5 space-y-2 bg-white rounded-xl">
                          <div className="flex justify-between items-center border-b border-gray-400 pb-3">
                            <span className="text-lg text-left font-semibold text-gray-900">
                              Personal Information
                            </span>
                          </div>
                          <div className="flex space-x-5 border-b border-gray-400">
                            <div className="flex items-center w-full">
                              <label
                                class="block text-gray-700 text-base whitespace-nowrap"
                                for="name"
                              >
                                Full Name:
                              </label>
                              <input
                                className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                                id="userName"
                                name="userName"
                                value={
                                  fieldsUser && fieldsUser["userName"]
                                    ? fieldsUser["userName"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser && errorsUser["userName"] ? (
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["userName"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="flex items-center w-full">
                              <label
                                class="block text-gray-700 text-base"
                                for="name"
                              >
                                Share:
                              </label>
                              <input
                                className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                                id="share"
                                name="share"
                                value={
                                  fieldsUser && fieldsUser["share"]
                                    ? fieldsUser["share"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser && errorsUser["share"] ? (
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["share"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="flex items-center w-full">
                              <label
                                class="block text-gray-700 text-base"
                                for="name"
                              >
                                Commission:
                              </label>
                              <input
                                className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                                id="comm"
                                name="comm"
                                value={
                                  fieldsUser && fieldsUser["comm"]
                                    ? fieldsUser["comm"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser && errorsUser["comm"] ? (
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["comm"]}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex space-x-5 items-center border-b border-gray-400">
                            <div className="flex items-center w-full">
                              <label
                                class="block text-gray-700 text-base"
                                for="name"
                              >
                                Mobile:
                              </label>
                              <input
                                className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                                id="mobNo"
                                name="mobNo"
                                value={
                                  fieldsUser && fieldsUser["mobNo"]
                                    ? fieldsUser["mobNo"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser && errorsUser["mobNo"] ? (
                                <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                  {errorsUser["mobNo"]}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex items-center w-full">
                            <label
                              class="block text-gray-700 text-base whitespace-nowrap"
                              for="name"
                            >
                              Creation Date:
                            </label>
                            <input
                              className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="createdAt"
                              name="createdAt"
                              value={
                                fieldsUser && fieldsUser["createdAt"]
                                  ? new Date(
                                      fieldsUser["createdAt"]
                                    ).toLocaleDateString()
                                  : ""
                              }
                              type="text"
                              onChange={inputChangeUser}
                              disabled
                            />
                            {errorsUser && errorsUser["createdAt"] ? (
                              <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                {errorsUser["createdAt"]}
                              </div>
                            ) : null}
                          </div>

                          <div className="flex space-x-5 border-b border-gray-400">
                            <div className="flex w-full items-center">
                              <button
                                className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateUserSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>

                          <div className="flex items-center w-full">
                            <label
                              class="block text-gray-700 text-base whitespace-nowrap"
                              for="name"
                            >
                              Change Password :
                            </label>
                            <input
                              className="w-full px-3 py-2 text-sm bg-white focus:outline-none"
                              id="password"
                              name="password"
                              type="text"
                              onChange={inputChangeUser}
                            />
                            {errorsUser && errorsUser["password"] ? (
                              <div className="text-red-600 whitespace-nowrap invalid-feedback">
                                {errorsUser["password"]}
                              </div>
                            ) : null}
                          </div>
                          <div className="flex space-x-5 border-b border-gray-400">
                            <div className="flex w-full items-center">
                              <button
                                className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                                onClick={() => updateUserSubmitPassword()}
                              >
                                Save Password
                              </button>
                            </div>
                          </div>
                        </div> */}

                        <div className="w-full p-5 space-y-6 bg-white rounded-xl shadow-md border">
                          <div className="flex justify-between items-center border-b border-gray-400 pb-3">
                            <span className="text-lg font-semibold text-gray-900">
                              Personal Information
                            </span>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 border-b border-gray-400 pb-5">
                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm md:text-base"
                                htmlFor="userName"
                              >
                                User Name:
                              </label>
                              <input
                                disabled
                                className="px-3 py-2 mt-1 text-sm bg-gray-100 border rounded-md focus:outline-none capitalize"
                                id="userName"
                                name="userName"
                                value={fieldsUser?.userName || ""}
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser?.userName && (
                                <div className="text-red-600 text-xs md:text-sm mt-1">
                                  {errorsUser.userName}
                                </div>
                              )}
                            </div>

                            {/* You can uncomment these sections when needed */}
                            {/* <div className="flex flex-col">
      <label
        className="block text-gray-700 text-sm md:text-base"
        htmlFor="share"
      >
        Share:
      </label>
      <input
        className="px-3 py-2 mt-1 text-sm bg-white border rounded-md focus:outline-none"
        id="share"
        name="share"
        value={fieldsUser?.share || ""}
        type="text"
        onChange={inputChangeUser}
      />
      {errorsUser?.share && (
        <div className="text-red-600 text-xs md:text-sm mt-1">
          {errorsUser.share}
        </div>
      )}
    </div>

    <div className="flex flex-col">
      <label
        className="block text-gray-700 text-sm md:text-base"
        htmlFor="comm"
      >
        Commission:
      </label>
      <input
        className="px-3 py-2 mt-1 text-sm bg-white border rounded-md focus:outline-none"
        id="comm"
        name="comm"
        value={fieldsUser?.comm || ""}
        type="text"
        onChange={inputChangeUser}
      />
      {errorsUser?.comm && (
        <div className="text-red-600 text-xs md:text-sm mt-1">
          {errorsUser.comm}
        </div>
      )}
    </div> */}
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 border-b border-gray-400 pb-4 relative">
                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm md:text-base"
                                htmlFor="mobNo"
                              >
                                Mobile:
                              </label>
                              <input
                                className="px-3 py-2 mt-1 text-sm bg-white border rounded-md focus:outline-none"
                                id="mobNo"
                                name="mobNo"
                                value={fieldsUser?.mobNo || ""}
                                type="text"
                                onChange={inputChangeUser}
                              />
                              {errorsUser?.mobNo && (
                                <div className="text-red-600 text-xs md:text-sm mt-1">
                                  {errorsUser.mobNo}
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col ">
                              <label
                                className="block text-gray-700 text-sm md:text-base"
                                htmlFor="createdAt"
                              >
                                Creation Date:
                              </label>
                              <input
                                className="px-3 py-2 mt-1 text-sm bg-gray-100 border rounded-md focus:outline-none"
                                id="createdAt"
                                name="createdAt"
                                value={
                                  fieldsUser?.createdAt
                                    ? new Date(fieldsUser.createdAt).toLocaleDateString()
                                    : ""
                                }
                                type="text"
                                disabled
                              />
                              {errorsUser?.createdAt && (
                                <div className="text-red-600 text-xs md:text-sm mt-1">
                                  {errorsUser.createdAt}
                                </div>
                              )}
                            </div>
                            <div className="  pt-6  ">
                              <div className="">
                                <button
                                  className="w-full px-4 py-2 font-medium text-blue-100 bg-blue-700 rounded-md shadow-sm hover:bg-blue-400"
                                  onClick={updateUserSubmit}
                                >
                                  Update Info
                                </button>
                              </div>
                            </div>
                          </div>



                          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-5">
                            <div className="flex flex-col">
                              <label
                                className="block text-gray-700 text-sm md:text-base"
                                htmlFor="passwords"
                              >
                                Change Password:
                              </label>
                              <input
                                className="px-3 py-2 mt-1 text-sm bg-white border border-gray-300 rounded-md focus:outline-none"
                                id="passwords"
                                value={
                                  fieldsUser?.passwords
                                    ? fieldsUser?.passwords
                                    : ""
                                }
                                name="passwords"
                                type="password"
                                onChange={inputChangeUser}
                              />
                              {errorsUser?.password && (
                                <div className="text-red-600 text-xs md:text-sm mt-1">
                                  {errorsUser.password}
                                </div>
                              )}
                            </div>

                            <div className=" pt-6">
                              <button
                                className="w-full px-5 py-2 bg-blue-700 text-white font-medium rounded-md shadow-sm hover:bg-blue-500 transition-colors"
                                onClick={updateUserSubmitPassword}
                              >
                                Save Password
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <div>
                        <div className="w-full my-2">
                          Block Games
                          <Multiselect
                            // key={resetKey}
                            options={options}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="name"
                            selectedValues={
                              marketSelected ? marketSelected : null
                            }
                          />
                        </div>
                        <button
                          onClick={() => BlockGame()}
                          className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                        >
                          Submit Block Games
                        </button>
                      </div> */}

                      {/* <div>
                        Blocked Games:
                        <ul>
                          {userDetailsById &&
                            userDetailsById.blockedGames?.length > 0 &&
                            userDetailsById.blockedGames
                              .flatMap((game) => game.games)
                              .map((game) => (
                                <li key={game.name}>{game.name}</li>
                              ))}
                        </ul>
                      </div> */}

                      <div className=" bg-white   rounded-lg">
                        {/* <div>
                          <select onChange={handleSlectMarket} className="">
                            <option value="">Select Market</option>
                            {allMarket &&
                              allMarket.map((e) => {
                                return (
                                  <option value={e?._id}>{e?.name}</option>
                                );
                              })}
                          </select>
                        </div> */}
                        {/* <div className="w-full my-2">
                          Block Number
                          <Multiselect
                 
                            options={selectMarketOption}
                            onSelect={onSelect2}
                     
                            displayValue="name"
                            selectedValues={
                              numberSelected ? numberSelected : null
                            }
                          />
                        </div> */}
                        {/* <button
                          onClick={() => BlockNumber()}
                          className="px-4 py-2 font-medium tracking-wider text-blue-100 bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400"
                        >
                          Submit Block Number
                        </button> */}

                        {/* <div>
                          {userDetailsById?.blockedNumbers?.map(
                            (blockedNumber) => (
                              <div
                                key={blockedNumber._id}
                                className="p-4 border-b border-gray-200"
                              >
                                <h3 className="text-lg font-semibold">
                                  Market: {blockedNumber.marketId?.name}
                                </h3>
                                {blockedNumber.numbers.map((numberGroup) => (
                                  <p
                                    key={numberGroup._id}
                                    className="text-sm mt-2"
                                  >
                                    Blocked Numbers:{" "}
                                    {numberGroup.numbers.join(", ")}
                                  </p>
                                ))}
                              </div>
                            )
                          )}
                        </div> */}

                        <div>
                          {userDetailsById?.blockedNumbers?.map(
                            (blockedNumber) => {
                              // Check if there are any blocked numbers in the current market
                              const hasBlockedNumbers =
                                blockedNumber.numbers.some(
                                  (numGroup) => numGroup.numbers.length > 0
                                );

                              if (!hasBlockedNumbers) {
                                return null; // Skip rendering if there are no blocked numbers in the current market
                              }

                              return (
                                <div
                                  key={blockedNumber._id}
                                  className="p-4 border-b border-gray-200"
                                >
                                  <h3 className="text-lg font-semibold">
                                    Market: {blockedNumber.marketId?.name}
                                  </h3>
                                  {blockedNumber.numbers.map((numberGroup) => (
                                    <p
                                      key={numberGroup._id}
                                      className="text-sm mt-2"
                                    >
                                      Blocked Numbers:{" "}
                                      {numberGroup.numbers.join(", ")}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>

                      {sessionData && sessionData.roleId == 4 && (
                        <div>
                          <input
                            type="checkbox"
                            checked={userDetailsById?.DoubleAmount}
                            onChange={(e) =>
                              handledoubleAmount(e, userDetailsById)
                            }
                            id="implementDouble"
                          />
                          <label htmlFor="implementDouble">
                            Implement Double
                          </label>
                        </div>
                      )}


                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleChange={handleChange}
        handleFund={handleFund}
        errorsGame={errorsGame}
      />
    </>
  );
};

export default Dashboard;
