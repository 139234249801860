import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { bank, bankActions, bankActionsActions, gamesActions, userActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { MdOutlineDelete } from "react-icons/md";

// import Table from '../../components/Atoms/Table/Table';


const Notification = () => {
  const dispatch = useDispatch();
  let addref = useRef(null)
  let selector = useSelector(state => state)
  const [currentPage3, setCurrentPage3] = useState(0);
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(50);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [selecteddata, setselecteddata] = useState([])


  useEffect(() => {
    let appsettingReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(bankActions.getNotificationList(appsettingReq));
    dispatch(gamesActions.getAllUser());
  }, []);


  const inputChangeCreate2 = (e) => {
    console.log(e)
    setselecteddata(e)
  };
  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }

  const handleCreateHideModal = (data) => {
    if (addref?.current) {
      addref.current.value = ""
    }
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  const handleUpdateOpenModal = (data) => {
    setFieldsUpdate(data);
    setUpdateModal(true);
  }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {

    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }
      let Object = {
        "id": fieldsUpdate?._id,
        "accountNo": fieldsUpdate?.accountNo && parseInt(fieldsUpdate?.accountNo),
        "name": fieldsUpdate?.name.trim(),
        "ifsc": fieldsUpdate?.ifsc.trim(),
        "bankName": fieldsUpdate?.bankName.trim(),
        "upiId": fieldsUpdate?.upiId.trim(),
      }
      dispatch(bankActions.updateAdminBankDetail(Object, appsettingPageRefresh))
      handleUpdateHideModal()



    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = "Please Enter name";
    }

    if (!fieldsUpdate.bankName || fieldsUpdate.bankName.trim() === "") {
      formIsValid = false;
      errors.bankName = "Please Enter Bank name";
    }

    if (!fieldsUpdate.ifsc || fieldsUpdate.ifsc.trim() === "") {
      formIsValid = false;
      errors.ifsc = "Please Enter IFSC Code";
    }
    if (!fieldsUpdate.accountNo || fieldsUpdate.accountNo === "") {
      formIsValid = false;
      errors.accountNo = "Please Enter Account Number";
    }
    if (!fieldsUpdate.upiId || fieldsUpdate.upiId.trim() === "") {
      formIsValid = false;
      errors.upiId = "Please Enter UPI Id";
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData, loading } = users ? users : {};
    e.preventDefault();
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }

    let arrayOfuser = selecteddata && selecteddata.length > 0 && selecteddata?.map((e) => e.value)

    console.log(arrayOfuser)
    if (handleValidationCreateAppSetting()) {
      console.log(fieldsAppSetting)

      let appsettingPageRefresh = {

        "title": fieldsAppSetting && fieldsAppSetting.name.trim() || "",

        "msg": fieldsAppSetting && fieldsAppSetting.key.trim() || "",

        // "image":fileData||" ",
        "userId": arrayOfuser && arrayOfuser.length > 0 ? arrayOfuser : []
      }
      dispatch(bankActions.createNotification(appsettingPageRefresh, appsettingReq))
      setFieldsAppSetting({})
      setErrorsAppSetting({})
      handleCreateHideModal()
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key.trim() === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

 

    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const disableAppSetting = (data) => {
    let appsettingIdReq = {
      "id": data._id,
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    confirmAlert({

      title: 'Confirm to disable Bank?',
      message: `Are you sure to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(bankActions.bankstatusUpdate(appsettingIdReq, appsettingPageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const deleteAppSetting = (data) => {
    let {ids} = data

    let appsettingIdReq = {
      "notificationId": ids,
    }

    let appsettingReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.title}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(bankActions.deleteNotification(appsettingIdReq, appsettingReq))
        },
        {
          label: 'No'
        }
      ]
    });
  }


  const handleFile = (event) => {
    console.log("handleFileevent", event);

    // this.setState({ selectedFile: event.target.files[0] });
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event))
      // this.setState({ selectedFile: null });
      setSelectedFile(null)

    }
    else {
      console.log("No File To Upload!")
    }

  }

  const handlePageClick = (data) => {

    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage3(data.selected)
    setPageNo(data.selected + 1);
    let pageReq = {
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(bankActions.getNotificationList(pageReq));
  }

  let { bank } = selector ? selector : {};
  console.log(bank)
  let { loading, adminBankDetail,notificationTotal,notificationList } = bank ? bank : {};
  let { total, list } = adminBankDetail ? adminBankDetail : {};
  const { games } = selector ? selector : {};
  let { allUserList } = games ? games : {};



  console.log("notificationListnotificationList",notificationList)

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className='text-2xl font-bold'>Notification Management</p>
                        </div>
                        <div>
                          <span className="relative ">
                            <button className="px-4 py-2 font-medium tracking-wider text-white bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400" onClick={() => handleCreateOpenModal()}>Create Notification</button>
                          </span>  &nbsp;
                        </div>
                      </sectiion>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            {/* <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white  ">
                              Our products
                            </caption> */}
                            <thead className="text-sm text-gray-800 uppercase bg-gray-50  ">
                              <tr>
                                <th scope="col" className="px-6 py-3">S.No.</th>
                                <th scope="col" className="px-6 py-3">Title</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Message</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap flex justify-end items-center">Action</th>
                          
                              </tr>
                            </thead>
                            <tbody>
                              {
                                notificationList && notificationList.length > 0 ?
                                  notificationList.map((element, index) => (
                                    <React.Fragment key={index}>
                                      {/* {JSON.stringify(element.title)} */}
                                      <tr key={index} className={index % 2 === 0 ? "bg-white border-b" : "bg-gray-100  "}>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {offset + index + 1}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.title ? element.title : "-"}
                                        </th>
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.body ? element.body : "-"}
                                        </th>
                                       
                                    
                                        <td className="px-6 py-4 text-right justify-end items-end flex gap-1">
                                          {/* <span className="relative ">
                                            <button className="px-4 py-2 font-medium tracking-wider text-white bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400" onClick={() => handleUpdateOpenModal(element)}>Edit</button>
                                          </span>  &nbsp; */}
                                          {/* <span className="relative ">

                                            {element.isDisable ?
                                              <button onClick={() => disableAppSetting(element)} className='px-4 py-2 font-medium tracking-wider text-white bg-orange-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-orange-400'>Disable</button>
                                              :
                                              <button className='px-4 py-2 font-medium tracking-wider text-white bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400'>Enable</button>
                                            }
                                          </span>  &nbsp; */}
                                          <span className="relative ">
                                            {/* <button className="px-4 py-2 font-medium tracking-wider text-white bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-300" onClick={() => deleteAppSetting(element)}>Delete</button> */}
                                            <MdOutlineDelete   onClick={() => deleteAppSetting(element)} title='Delete' className=' text-2xl text-red-600 cursor-pointer'/>
                                          </span>  &nbsp;

                                        </td>



                                      </tr>
                                    </React.Fragment>
                                  )) : <td> No Data Found</td>
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>



                    </div>
                  </div>

                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          notificationTotal && notificationTotal > size ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={notificationTotal / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage3}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          notificationTotal && notificationTotal > size ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={notificationTotal / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage3}
                            />
                            : null}
                      </nav>
                  }


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        allUserList={allUserList}
        addref={addref}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        selecteddata={selecteddata}
        setselecteddata={setselecteddata}
        inputChangeCreate2={inputChangeCreate2}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        // inputChange={inputChange}
        rowData={rowData}
      // errorsUpdate={errorsUpdate}
      // updateAppSettingSubmit={updateAppSettingSubmit}
      />


    </>
  );
};

export default Notification;
