import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardJSON from './AppSettingManagement.json'
import { appSettingActions, userActions } from '../../_actions';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";
import UpdateAppSettingModal from "./components/UpdateAppSettingModal/UpdateAppSettingModal";
import ViewAppSettingModal from "./components/ViewAppSettingModal/ViewAppSettingModal";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import Loader from '../../components/Loader/Loader';
import { FaRegSave } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';




const AppSettingManagement = () => {
  const dispatch = useDispatch();
  let [selectedFile, setSelectedFile] = useState(null);
  let [rowData, setRowData] = useState(null);
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsAppSetting, setFieldsAppSetting] = useState({});
  const [errorsAppSetting, setErrorsAppSetting] = useState({});
  const [size, setSize] = useState(100);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [value, setValue] = useState('');
  const selector = useSelector(state => state);
  const { appSettingList, total, loading } = useSelector(state => state.appSettings);

  const [initialState, setInitialState] = useState({
    _id: '',
    name: '',
    key: '',
    value: '',
  });




  // console.log(initialState)

  useEffect(() => {
    let appsettingReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(appSettingActions.getAppSettingList(appsettingReq));

  }, []);


  const handleCreateOpenModal = () => {
    setCreateModal(true);
  }
  const savedatadata = (e) => {
    console.log(e)
  }



  const handleCreateHideModal = (data) => {
    setCreateModal(false);
    setFieldsAppSetting({});
    setErrorsAppSetting({});
  }

  // const handleUpdateOpenModal = (data) => {
  //   setFieldsUpdate(data);
  //   setUpdateModal(true);
  // }

  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  }

  const handleViewOpenModal = (data) => {
    setRowData(data);
    setViewModal(true);
  }



  const handleViewHideModal = (data) => {
    setViewModal(false);
  }

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdate(prevState => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    // console.log(e)
    // const { name, value } = e.target;
    // setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }));
    // setErrorsAppSetting(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsUpdate;
      dispatch(appSettingActions.updateAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setUpdateModal, appsettingPageRefresh));
    }
  };

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsUpdate(errors);
    return formIsValid;
  };


  const createAppSettingSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateAppSetting()) {

      let appsettingPageRefresh = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }

      const { name, key, value, _id } = fieldsAppSetting;
      dispatch(appSettingActions.createAppSetting(
        {
          name: name,
          key: key,
          value: value,
          id: _id
        }
        , setCreateModal, setFieldsAppSetting, appsettingPageRefresh));
    }
  };

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsAppSetting.name || fieldsAppSetting.name === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === "") {
      formIsValid = false;
      errors.key = DashboardJSON.ErrorMsg.key;
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === "") {
      formIsValid = false;
      errors.value = DashboardJSON.ErrorMsg.value;
    }


    setErrorsAppSetting(errors);
    return formIsValid;
  };


  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
    } else {
      console.log("No File To Upload!");
    }
  };

  const [state1, setstate1] = useState("")
  const [state2, setstate2] = useState("")
  const [state3, setstate3] = useState("")
  const [state4, setstate4] = useState("")
  const [state5, setstate5] = useState("")
  const [state10, setstate10] = useState("")
  const [state6, setstate6] = useState("")
  const [state99, setstate99] = useState("")
  const [state100, setstate100] = useState("")

  const [state555, setstate555] = useState("")
  const [state5555, setstate5555] = useState("")


  const [state101, setstate101] = useState("")
  const [state55, setstate55] = useState("")
  const [state852, setstate852] = useState("")
  const [state853, setstate853] = useState("")





  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach((element) => {

        if (element.key === "min_Deposit") {
          setstate555(element.value);
        }
        if (element.key === "min_Withdrawl") {
          setstate852(element.value);
        }
        if (element.key === "max_Deposit") {
          setstate5555(element.value);
        }

        if (element.key === "Register_Bonus") {
          setstate55(element.value);
        }
        if (element.key === "Version") {
          setstate6(element.value);
        }
        if (element.key === "Update_Link") {
          setstate99(element.value);
        }
        if (element.key === "YoutubeLink") {
          setstate1(element.value);
        }
        if (element.key === "Email") {
          setstate4(element.value);
        }
        if (element.key === "WhatsApp") {
          setstate3(element.value);
        }
        if (element.key === "MobileNumber") {
          setstate2(element.value);
        }
        if (element.key === "TelegramLink") {
          setstate5(element.value);
        }
        if (element.key === "HowToPlay") {
          setValue(element.value);
        }
        if (element.key === "UPI_IMAGE") {
          setstate10(element.value);
        }
        if (element.key === "withdrawl_Open_Time") {
          setstate100(element.value);
        }

        if (element.key === "withdrawl_close_Time") {
          setstate101(element.value);
        }

        if (element.key === "max_Withdrawl") {
          setstate853(element.value);
        }

        
      });
    }
  }, [appSettingList]);

  const saveInfo = async (e) => {
    console.log("------------>>>", e)
    let dataofvalue;
    if (e.key === "UPI_IMAGE") {
      let { users } = selector ? selector : {}
      let { fileData } = users ? users : {}
      dataofvalue = fileData ? fileData : state10;
    }
    if (e.key === "min_Deposit") {
      dataofvalue = state555;
    }
    if (e.key === "max_Deposit") {
      dataofvalue = state5555;
    }

    if (e.key === "Register_Bonus") {
      dataofvalue = state55;
    }
    if (e.key === "Update_Link") {
      dataofvalue = state99;
    }
    if (e.key === "TelegramLink") {
      dataofvalue = state5;
    }
    if (e.key === "Version") {
      dataofvalue = state6;
    }
    if (e.key === "YoutubeLink") {
      dataofvalue = state1;
    }
    if (e.key === "Email") {
      dataofvalue = state4;
    }
    if (e.key === "WhatsApp") {
      dataofvalue = state3;
    }
    if (e.key === "MobileNumber") {
      dataofvalue = state2;
    }
    if (e.key === "HowToPlay") {
      dataofvalue = value;
    }
    if (e.key === "withdrawl_Open_Time") {
      dataofvalue = state100;
    }
    if (e.key === "withdrawl_close_Time") {
      dataofvalue = state101;
    }
    if (e.key === "min_Withdrawl") {
      dataofvalue = state852;
      // setstate852(element.value);
    }
    if (e.key === "max_Withdrawl") {
      dataofvalue = state853;
      // setstate852(element.value);
    }
    let appsettingPageRefresh = {
      "keyWord": "",
      "pageNo": pageNo,
      "size": size
    }
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id
    }
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh))


  }

  console.log("appSettingListappSettingList", appSettingList)
  console.log("state101state101", state101)

  let { users } = selector ? selector : {}
  let { fileData } = users ? users : {}
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div>
                          <p className='text-2xl font-bold'>App Setting Management</p>
                        </div>
                        <div>
                          <span className="relative ">

                          </span>  &nbsp;
                        </div>

                      </sectiion>

                      <div>
                        <div className="relative  flex flex-col gap-5  ">
                          <div className='grid grid-cols-1 lg:grid-cols-2 items-center p-2 shadow-md gap-5 bg-white rounded-md border '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {




                                  if (element.key === "MobileNumber") {
                                    return (


                                      <form class="relative w-full">

                                        <div className=' text-xl mb-2 px-2'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">MobileNumber </label>
                                          <input rows={3} value={state2} onChange={(e) => setstate2(e.target.value)} type="text" id="text" class="bg-gray-50  border border-[#efefef] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        </div>
                                        <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className='text-[#3e44d5] absolute  top-1/2 right-3 text-xl cursor-pointer' />

                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  else if (element.key === "Version") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934]">{element && element.name} </label>
                                          <input value={state6} onChange={(e) => setstate6(e.target.value)} type="number" id="text" class="bg-gray-50  w-full  border border-[#efefef] text-gray-900 text-sm rounded- text-[14px]focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  } else if (element.key === "Update_Link") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-gray-900 ">{element && element.name} </label>
                                          <input value={state99} onChange={(e) => setstate99(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize  w-full  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#F64401] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  else if (element.key === "TelegramLink") {
                                    return (


                                      <form class="flex  w-full justify-start items-center  ">

                                        <div className=' relative w-full'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934]">{element && element.name} </label>
                                          <input value={state5} onChange={(e) => setstate5(e.target.value)} type="text" id="text" class="bg-gray-50  w-full  border border-[#efefef] text-gray-900 text-sm rounded- text-[14px]focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  else if (element.key === "WhatsApp") {
                                    return (


                                      <form class="flex w-full  justify-start items-center mt-2 relative ">
                                        <div className='w-full '>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934]">WhatsApp </label>
                                          <input rows={3} value={state3} onChange={(e) => setstate3(e.target.value)} type="text" id="text" class="bg-gray-50 w-full border border-[#efefef] text-[#242934] text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block  px-6 py-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                        </div>
                                        <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        <div>
                                        </div>
                                      </form>

                                    )

                                  }
                                  else if (element.key === "Email") {
                                    return (
                                      <form class="flex w-full  justify-start items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934]">Withdraw message </label>
                                          <textarea rows={2} value={state4} onChange={(e) => setstate4(e.target.value)} type="text" id="text" class="bg-gray-50 capitalize border border-[#efefef] text-gray-900 text-sm rounded text-[14px]focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  else if (element.key === "YoutubeLink") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">{element && element.name} </label>
                                          <textarea style={{ resize: 'none' }} rows={3} value={state1} onChange={(e) => setstate1(e.target.value)} type="text" id="text" class="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px]focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  } else if (element.key === "Register_Bonus") {
                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] "> ₹ {element && element.name}  </label>
                                          <input value={state55} onChange={(e) => setstate55(e.target.value)} type="number" id="text" class="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px]focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }



                                }) : null
                            }


                            <div className=' items-center gap-5  '>
                              {
                                appSettingList && appSettingList.length > 0 ?
                                  appSettingList.map((element, index) => {

                                    if (element.key === "withdrawl_Open_Time") {
                                      return (


                                        <form class="flex  justify-start w-full items-center mt-2 ">

                                          <div className='w-full relative'>
                                            <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">{element && element.name} </label>
                                            <input value={state100} onChange={(e) => setstate100(e.target.value)} type="time" id="text" class="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px]focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                            <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                          </div>
                                          <div>

                                          </div>


                                        </form>

                                      )

                                    }
                                  }) : null
                              }
                            </div>
                            <div className=' items-center gap-5  '>
                              {
                                appSettingList && appSettingList.length > 0 ?
                                  appSettingList.map((element, index) => {

                                    if (element.key === "withdrawl_close_Time") {
                                      return (


                                        <form class="flex  justify-start w-full items-center mt-2 ">

                                          <div className='w-full relative'>
                                            <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">{element && element.name} </label>
                                            <input value={state101} onChange={(e) => {
                                              console.log(e.target.value); // Log the new value
                                              setstate101(e.target.value); // Update state
                                            }}
                                              type="time" id="text" class="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px]focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                            <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                          </div>
                                          <div>

                                          </div>


                                        </form>

                                      )

                                    }
                                  }) : null
                              }
                            </div>
                            <div className=' items-center gap-5  '>
                              {
                                appSettingList && appSettingList.length > 0 ?
                                  appSettingList.map((element, index) => {

                                    if (element.key === "UPI_IMAGE") {
                                      return (
                                        <>
                                          <div className='w-full '>
                                            <form className="flex justify-start w-full items-center mt-3">
                                              <div className='w-full  flex justify-between place-items-center'>
                                                <div className='relative w-[70%]'>
                                                  <input
                                                    type="file"
                                                    id="image"
                                                    accept="image/*"
                                                    onChange={(e) => handleFile(e)}
                                                    className="bg-gray-50 pe-12 border border-[#efefef] text-[#242934] text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    required
                                                  />


                                                  <FaRegSave
                                                    title='Save'
                                                    onClick={(e) => saveInfo(element, e)}
                                                    className='text-[#3e44d5] absolute top-2.5 right-2 text-xl cursor-pointer'
                                                  />

                                                </div>
                                                <div className=''>
                                                  <label htmlFor="image" className="block mb-1 text-sm font-medium text-[#242934] ">
                                                    {element && element.name}
                                                  </label>
                                                  <img src={fileData ? fileData : state10} className=' w-28 h-28' alt='barcode' />

                                                </div>





                                              </div>
                                            </form>
                                          </div>

                                        </>
                                      )
                                    }

                                  }) : null
                              }
                            </div>
                          </div>






                          <div className=' items-center gap-5  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {

                                  if (element.key === "max_Deposit") {

                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">Maximum Deposit ₹ </label>
                                          <input
                                            value={state5555}
                                            onChange={(e) => setstate5555(e.target.value)}
                                            type="number"
                                            id="text"
                                            className="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />
                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }
                                  if (element.key === "min_Deposit") {

                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">Minimum Deposit ₹ </label>
                                          <input
                                            value={state555}
                                            onChange={(e) => setstate555(e.target.value)}
                                            type="number"
                                            id="text"
                                            className="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />

                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }







                                  if (element.key === "min_Withdrawl") {

                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">Minimum Withdrawl ₹ </label>
                                          <input
                                            value={state852}
                                            onChange={(e) => setstate852(e.target.value)}
                                            type="number"
                                            id="text"
                                            className="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />

                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }






                                  if (element.key === "max_Withdrawl") {

                                    return (


                                      <form class="flex  justify-start w-full items-center mt-2 ">

                                        <div className='w-full relative'>
                                          <label for="text" class="block mb-1 text-sm font-medium text-[#242934] ">Maximum Withdrawl ₹ </label>
                                          <input
                                            value={state853}
                                            onChange={(e) => setstate853(e.target.value)}
                                            type="number"
                                            id="text"
                                            className="bg-gray-50 pe-12 border border-[#efefef] text-gray-900 text-sm rounded text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                          />

                                          <FaRegSave title='Save' onClick={(e) => saveInfo(element, e)} className=' text-[#3e44d5] absolute  top-1/2 right-2 text-xl cursor-pointer' />
                                        </div>
                                        <div>

                                        </div>


                                      </form>

                                    )

                                  }




                                }) : null
                            }
                          </div>







                          <div className='grid grid-cols-1 lg:grid-cols-3 items-center gap-5  '>
                            {
                              appSettingList && appSettingList.length > 0 ?
                                appSettingList.map((element, index) => {






                                  if (element.key === "UPI_IMAGE") {
                                    return (
                                      <form className="flex justify-start w-full items-center mt-2">
                                        <div className='w-full relative'>
                                          {/* <img src={fileData?fileData:state10} className=' w-28 h-28'/> */}
                                          {/* <label htmlFor="image" className="block mb-1 text-sm font-medium text-gray-900">
                                            {element && element.name}
                                          </label> */}


                                          {/* <input 
                                            type="file" 
                                            id="image" 
                                            accept="image/*" 
                                            onChange={(e) => handleFile(e)} 
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            required
                                          /> */}


                                          {/* <FaRegSave 
                                            title='Save' 
                                            onClick={(e) => saveInfo(element, e)} 
                                            className='text-[#F64401] absolute top-1/2 right-2 text-xl cursor-pointer' 
                                          /> */}
                                        </div>
                                      </form>
                                    )
                                  }

                                }) : null
                            }
                          </div>










                          <div className=' flex-1 h-full bg-white shadow-md border rounded-md p-4 '>
                            {appSettingList && appSettingList.length > 0 ? (
                              appSettingList.map((element, index) => {
                                if (element && element.key && element.key === "HowToPlay") {
                                  return (
                                    <div className=''>
                                      <div className=' mb-1'> How To Play</div>
                                      <ReactQuill className='h-96 w-full pb-14' theme="snow" value={value} onChange={setValue} />
                                      <div className='flex justify-end'>

                                        <button className='bg-[black] text-sm px-3  text-white w-auto rounded shadow-md font-semibold p-1' onClick={(e) => saveInfo(element, e)}> Save</button>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                            ) : null}



                          </div>

                        </div>
                      </div>



                    </div>
                  </div>

                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          total && total > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={total / size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>





      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />


    </>
  );
};

export default AppSettingManagement;
