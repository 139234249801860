import axios from "axios";
// let backendUrl = `http://localhost:8804/api`;
let backendUrl = `https://www.7starff.in/api`;



export function authHeader() {
  let user = JSON.parse(window.sessionStorage.getItem("adminuser"));
  if (user && user.token) {
    return { Authorization: "Bearer " + user.token }; // returning an object
  } else {
    return {}; // returning an empty object if no user or token
  }
}

export function logoutFunction() {
  localStorage.removeItem("adminuser");
  window.location.replace("/login");
  window.location.reload();
}

export const headerForPublicAPI = new Headers({
  "Content-Type": "application/json",
});

export const headerForPrivateAPI = new Headers({
  "Content-Type": "application/json",
  Authorization: authHeader().Authorization,
});


export const headerForPrivateMediaAPI = new Headers({
  "Content-Type": "multipart/form-data",
  Authorization: authHeader().Authorization,
});

export const APIcallFunction = async (credentials) => {
  const requestOptions = {
    method: credentials.method,
    headers: credentials.header,
    body: JSON.stringify(credentials.body),
  };
  try {
    const response = await fetch(
      `${backendUrl}/v1${credentials.endPoint}`,
      requestOptions
    );

    const responseData = await handleResponse(response);

    return {
      data: responseData.data,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const APIcallFunctionForImage = async (credentials) => {
  try {
    const endpointUrl = `${backendUrl}` + credentials.endPoint;
    const headers = {
      Authorization: authHeader().Authorization,
      "Content-Type": "multipart/form-data",
    };
    const response = await axios.post(endpointUrl, credentials.body, {
      headers,
    });

    // const responseData = await handleResponse(response);
    return {
      data: response.data,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    // console.log(data);

    if (!response.ok) {
      if (response.status === 401) {
        logoutFunction();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.error) {
      if (data.code === 3) {
        logoutFunction();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
