// import React from 'react';

// export default function Loader({ loading }) {
//   return (
//     <div>
//       {true && (
//         <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
//           <div className="flex flex-col items-center space-y-4">
//             <div className="relative">
//               <div className="w-16 h-16 border-4 border-t-4 border-blue-500 border-opacity-20 border-t-blue-500 rounded-full animate-spin"></div>
//               <div className="absolute inset-0 flex items-center justify-center">
//                 <div className="w-8 h-8 border-4 border-t-4 border-green-500 border-opacity-20 border-t-green-500 rounded-full animate-spin animate-spin-reverse"></div>
//               </div>
//             </div>
//             <p className="text-white text-lg font-semibold">Please wait...</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }



import React from 'react';
// import { connect } from 'react-redux';


export default function Loader(props) {
    let { loading } = props;
    // console.log(loading)
    return (
        <>
            {
                loading ?
                    <>
                        <div className='fixed inset-0 w-full  h-screen flex justify-center items-center bg-white/50 z-40'>
                            <div class="sk-fading-circle">
                                <div class="sk-circle1 sk-circle"></div>
                                <div class="sk-circle2 sk-circle"></div>
                                <div class="sk-circle3 sk-circle"></div>
                                <div class="sk-circle4 sk-circle"></div>
                                <div class="sk-circle5 sk-circle"></div>
                                <div class="sk-circle6 sk-circle"></div>
                                <div class="sk-circle7 sk-circle"></div>
                                <div class="sk-circle8 sk-circle"></div>
                                <div class="sk-circle9 sk-circle"></div>
                                <div class="sk-circle10 sk-circle"></div>
                                <div class="sk-circle11 sk-circle"></div>
                                <div class="sk-circle12 sk-circle"></div>
                            </div>
                        </div>
                    </> :
                    null
            }
        </>


    );
}








