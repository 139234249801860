export const walletConstants = {
    GET_APP_REJ__REQUEST:"GET_APP_REJ__REQUEST",
    GET_APP_REJ__SUCCESS:"GET_APP_REJ__SUCCESS",
    GET_APP_REJ__FAILURE:"GET_APP_REJ__FAILURE",


    GET_BET_TOTAL_COUNT_REQUEST:"GET_BET_TOTAL_COUNT_REQUEST",
    GET_BET_TOTAL_COUNT_SUCCESS:"GET_BET_TOTAL_COUNT_SUCCESS",
    GET_BET_TOTAL_COUNT_FAILURE:"GET_BET_TOTAL_COUNT_FAILURE",


CREDIT_REFEFENCE_REQUEST:"CREDIT_REFEFENCE_REQUEST",
CREDIT_REFEFENCE_SUCCESS:"CREDIT_REFEFENCE_SUCCESS",
CREDIT_REFEFENCE_FAILURE:"CREDIT_REFEFENCE_FAILURE",


    GET_TRANSFER_POINTS_REQUEST:"GET_TRANSFER_POINTS_REQUEST",
    GET_TRANSFER_POINTS_SUCCESS:"GET_TRANSFER_POINTS_SUCCESS",
    GET_TRANSFER_POINTS_FAILURE:"GET_TRANSFER_POINTS_FAILURE",
    CREATE_WALLET_REQUEST: 'CREATE_WALLET_REQUEST',
    CREATE_WALLET_SUCCESS: 'CREATE_WALLET_SUCCESS',
    CREATE_WALLET_FAILURE: 'CREATE_WALLET_FAILURE',

    GET_ALL_WALLET_REQUEST: 'GET_ALL_WALLET_REQUEST',
    GET_ALL_WALLET_SUCCESS: 'GET_ALL_WALLET_SUCCESS',
    GET_ALL_WALLET_FAILURE: 'GET_ALL_WALLET_FAILURE',

    UPDATE_USER_BANK_DETAILS_REQUEST: 'UPDATE_USER_BANK_DETAILS_REQUEST',
    UPDATE_USER_BANK_DETAILS_SUCCESS: 'UPDATE_USER_BANK_DETAILS_SUCCESS',
    UPDATE_USER_BANK_DETAILS_FAILURE: 'UPDATE_USER_BANK_DETAILS_FAILURE',

    GET_PAYMENT_LIST_REQUEST: 'GET_PAYMENT_LIST_REQUEST',
    GET_PAYMENT_LIST_SUCCESS: 'GET_PAYMENT_LIST_SUCCESS',
    GET_PAYMENT_LIST_FAILURE: 'GET_PAYMENT_LIST_FAILURE',

    UPDATE_WALLET_REQUEST: 'UPDATE_WALLET_REQUEST',
    UPDATE_WALLET_SUCCESS: 'UPDATE_WALLET_SUCCESS',
    UPDATE_WALLET_FAILURE: 'UPDATE_WALLET_FAILURE',

    UPDATE_WALLET_STATUS_REQUEST: 'UPDATE_WALLET_STATUS_REQUEST',
    UPDATE_WALLET_STATUS_SUCCESS: 'UPDATE_WALLET_STATUS_SUCCESS',
    UPDATE_WALLET_STATUS_FAILURE: 'UPDATE_WALLET_STATUS_FAILURE',

    DELETE_WALLET_REQUEST: 'DELETE_WALLET_REQUEST',
    DELETE_WALLET_SUCCESS: 'DELETE_WALLET_SUCCESS',
    DELETE_WALLET_FAILURE: 'DELETE_WALLET_FAILURE',

    WITHDRAW_BALANCE_BY_ADMIN_REQUEST: 'WITHDRAW_BALANCE_BY_ADMIN_REQUEST',
    WITHDRAW_BALANCE_BY_ADMIN_SUCCESS: 'WITHDRAW_BALANCE_BY_ADMIN_SUCCESS',
    WITHDRAW_BALANCE_BY_ADMIN_FAILURE: 'WITHDRAW_BALANCE_BY_ADMIN_FAILURE',

    DEPOSITE_BALANCE_BY_ADMIN_REQUEST: 'DEPOSITE_BALANCE_BY_ADMIN_REQUEST',
    DEPOSITE_BALANCE_BY_ADMIN_SUCCESS: 'DEPOSITE_BALANCE_BY_ADMIN_SUCCESS',
    DEPOSITE_BALANCE_BY_ADMIN_FAILURE: 'DEPOSITE_BALANCE_BY_ADMIN_FAILURE',

};
