import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { MdOutlineCreditCard } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { FaShoppingBasket } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { LuGamepad2 } from "react-icons/lu";
import { MdNotificationsNone } from "react-icons/md";
import { BiWalletAlt } from "react-icons/bi";
import { TbReportAnalytics } from "react-icons/tb";
// import { IoMdMenu } from "react-icons/io";
import { GiAngelWings } from "react-icons/gi";
import { TbTargetArrow } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import profitTowin from "./profitTowin.png";
import { CgMenuRight } from "react-icons/cg";
import { TbSettingsHeart } from "react-icons/tb";
import { GiPodiumWinner } from "react-icons/gi";


function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          }  relative border-r py-[27px] px-10 items-center justify-center text-white  focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50  `}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <CgMenuRight className="text-white text-2xl  " />
      </button>

      <div
        className={`${navbarOpen === false ? "hidden" : "flex"
          } fixed left-0 inset-y-0   w-72 z-50 flex-col `}
      >
        <div className="flex flex-col w-72 relative flex-1 h-full max-h-full">

          <div className="cursor-pointer relative flex items-center justify-between place-items-center flex-shrink-0 px-4 bg-[black] w-full z-50 border-r">
            <img
              src={profitTowin}
              alt=""
              className=" relative flex justify-center w-20 h-20  items-center  md:ml-6"
            />
            {/* <h1 className=" text-white">
                            {SidebarJSON.SidebarHeaderName}
                        </h1> */}
            <div className="  items-end absolute right-3 top-7">
              <GiHamburgerMenu
                onClick={handleNavbar}
                className=" text-white text-xl  block"
              />
            </div>
          </div>
          <nav className="overflow-y-auto bg-[black] flex flex-col flex-wrap items-center justify-between relative  w-72 z-10 px-3  h-full overflow-auto border-t ">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="">
                  <span
                    onClick={() =>
                      onClickMenu("/app/dashboard", handleNavbar())
                    }
                    className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/dashboard"
                      ? "text-white bg-[#945908] rounded-md"
                      : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                      }`}
                  >
                    <span className="group-hover:text-[#945908]">
                      <FaHome size={20} />
                    </span>
                    <span className="text-sm  lg:font-lg">Dashboard</span>
                  </span>
                  {
                    <span
                      onClick={() => onClickMenu("/app/user", handleNavbar())}
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150   ${location.pathname === "/app/user" ||
                        location.pathname.includes("/app/user/") ||
                        location.pathname.includes("/app/EditUser/")
                        ? "text-white bg-[#945908] rounded-md"
                        : "text-white"
                        }`}
                    >
                      <span className="text-white  rounded-md   hover:text-[#945908]">
                        <FaUserGear size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">
                        User Management
                      </span>
                    </span>
                  }
                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResult', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]"}`}>
                                            <span className="group-hover:text-[#945908]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare Result</span>
                                        </span>} */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 &&
                                        <span onClick={() => onClickMenu('/app/declareResultFatafat', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultFatafat" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]"}`}>
                                            <span className="group-hover:text-[#945908]"><FaRegNewspaper size={20} /></span>
                                            <span className="text-sm  lg:font-lg">Declare FataFat Result</span>
                                        </span>} */}

                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <span
                      onClick={() =>
                        onClickMenu("/app/winnerList", handleNavbar())
                      }
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/winnerList"
                        ? "text-white bg-[#945908] rounded-md"
                        : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                        }`}
                    >
                      <span className="group-hover:text-[#945908]">
                        <GiAngelWings size={20} />
                      </span>
                      <span className="text-sm  lg:font-lg">Winner List</span>
                    </span>
                  )}
                  <h2 className=" w-full h-[2px] bg-[black] my-1"></h2>


                  <span onClick={() => onClickMenu('/app/notification', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/notification" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]"}`}>
                    <span className="group-hover:text-[#945908]"><MdNotificationsNone size={20} /></span>
                    <span className="text-sm  lg:font-lg">Notification Managemnt</span>
                  </span>

                  {/* <span onClick={() => onClickMenu('/app/appsetting',handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]" }`}>
                                        <span className="group-hover:text-[#945908]"><MdOutlineCreditCard size={20} /></span>
                                        <span className="text-sm  lg:font-lg">App Setting Management</span>
                                    </span> */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && <span onClick={() => onClickMenu('/app/bankdetails', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/bankdetails" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]"}`}>
                                        <span className="group-hover:text-[#744939]"><BsBank2 size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Bank Management</span>
                                    </span>} */}
                  {/* SUPER_ADMIN: 0,
                ADMIN: 1,
                SUPER_MASTER: 2,
                MASTER: 3,
                AGENT: 4,
                OPERATOR: 20,
                CLIENT: 100 */}

                  {/* {localStoragedata && localStoragedata.roleId == 0 && (
                    <>
                      <span
                        onClick={() =>
                          onClickMenu("/app/downsideUser", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/downsideUser"
                            ? "text-white bg-[#945908] rounded-md"
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <span className="text-sm  lg:font-lg">ADMIN</span>
                      </span>
                    </>
                  )} */}
                  {/* {localStoragedata && localStoragedata.roleId == 1 && (
                      <span className="text-sm  lg:font-lg">SUPER MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 2 && (
                      <span className="text-sm  lg:font-lg">MASTER</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 3 && (
                      <span className="text-sm  lg:font-lg">AGENT</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 4 && (
                      <span className="text-sm  lg:font-lg">OPERATOR</span>
                    )}
                    {localStoragedata && localStoragedata.roleId == 20 && (
                      <span className="text-sm  lg:font-lg">CLIENT</span>
                    )} */}

                  {/* <span onClick={() => onClickMenu('/app/notification', handleNavbar())} className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/notification" ? "text-white bg-[#945908] rounded-md" : "text-white bg-[black] rounded-md   hover:text-[#945908]"}`}>
                                        <span className="group-hover:text-[#945908]"><MdNotificationsNone size={20} /></span>
                                        <span className="text-sm  lg:font-lg">Notification Managemnt</span>
                                    </span> */}
                  {/* 
                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className="cursor-pointer group">
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#945908]">
                            <TbSettingsHeart size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#945908]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#945908]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#945908]">
                            <TbSettingsHeart size={20} />{" "}
                          </span>
                          <span className="text-sm text-white bg-[black] rounded-md w-[150px]  hover:text-[#945908]  lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#945908]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#945908]" />
                          )}
                        </span>
                      )}
                      {showSection === "settingManagement" ? (
                        <div className="  flex flex-col pl-10">
                          <span
                            onClick={() =>
                              onClickMenu("/app/appsetting", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/appsetting"
                                ? "text-white bg-[#945908] rounded-md"
                                : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              App Setting
                            </span>
                          </span>
                          <span
                            onClick={() =>
                              onClickMenu("/app/slider", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/slider"
                                ? "text-white bg-[#945908] rounded-md"
                                : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm  lg:font-lg">
                              Slider Setting
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )} */}
                  {localStoragedata && localStoragedata.roleId == 0 && (
                    <div className={`cursor-pointer group`}>
                      {showSection === "settingManagement" ? (
                        <span
                          onClick={() => setShowSection("")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-300 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#945908]">
                            <TbSettingsHeart size={20} />
                          </span>
                          <span className="text-sm group-hover:text-[#945908] w-[150px] lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#945908]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#945908]" />
                          )}
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection("settingManagement")}
                          className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-300 ease-in-out cursor-pointer h-9"
                        >
                          <span className="group-hover:text-[#945908]">
                            <TbSettingsHeart size={20} />
                          </span>
                          <span className="text-sm text-white bg-[black] rounded-md w-[150px] hover:text-[#945908] lg:font-lg">
                            Setting Management
                          </span>
                          {showSection === "settingManagement" ? (
                            <HiChevronUp className="group-hover:text-[#945908]" />
                          ) : (
                            <HiChevronDown className="group-hover:text-[#945908]" />
                          )}
                        </span>
                      )}
                      <div
                        className={`transition-all duration-500 ease-in-out overflow-hidden ${showSection === "settingManagement" ? 'max-h-[500px]' : 'max-h-0'}`}
                      >
                        <div className="flex flex-col pl-10">
                          <span
                            onClick={() => onClickMenu("/app/appsetting", handleNavbar())}
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/appsetting"
                              ? "text-white bg-[#945908] rounded-md"
                              : "text-white bg-[black] rounded-md hover:text-[#945908]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm lg:font-lg">App Setting</span>
                          </span>
                          <span
                            onClick={() => onClickMenu("/app/slider", handleNavbar())}
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/slider"
                              ? "text-white bg-[#945908] rounded-md"
                              : "text-white bg-[black] rounded-md hover:text-[#945908]"
                              }`}
                          >
                            <MdKeyboardArrowRight />{" "}
                            <span className="text-sm lg:font-lg">Slider Setting</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}


                  <h2 className=" w-full h-[2px] bg-[black] my-1"></h2>
                </div>


                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className={`cursor-pointer group`}>
                    {showSection === "FM" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-300 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <GiPodiumWinner size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#945908] w-[150px] lg:font-lg">
                          FataFat Management
                        </span>
                        {showSection === "FM" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("FM")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-300 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <GiPodiumWinner size={20} />
                        </span>
                        <span className="text-sm text-white bg-[black] rounded-md w-[150px] hover:text-[#945908] lg:font-lg">
                          FataFat Management
                        </span>
                        {showSection === "FM" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    )}
                    <div
                      className={`transition-all duration-500 ease-in-out overflow-hidden ${showSection === "FM" ? 'max-h-[500px]' : 'max-h-0'}`}
                    >
                      <div className="flex flex-col pl-10">
                        {localStoragedata && localStoragedata.roleId == 0 && (
                          <span
                            onClick={() =>
                              onClickMenu("/app/FatafatwinnerList", handleNavbar())
                            }
                            className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/FatafatwinnerList"
                              ? "text-white bg-[#945908] rounded-md"
                              : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                              }`}
                          >
                            <span className="group-hover:text-[#945908]">
                              <MdKeyboardArrowRight />
                            </span>
                            <span className="text-sm  lg:font-lg">FataFat Winner List</span>
                          </span>
                        )}
                        <span
                          onClick={() => onClickMenu("/app/brand", handleNavbar())}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/brand"
                            ? "text-white bg-[#945908] rounded-md"
                            : "text-white bg-[black] rounded-md hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm lg:font-lg">Brand</span>
                        </span>
                        <span
                          onClick={() => onClickMenu("/app/Brandgame", handleNavbar())}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/Brandgame"
                            ? "text-white bg-[#945908] rounded-md"
                            : "text-white bg-[black] rounded-md hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm lg:font-lg">Brand Game</span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/fatafatbetHistory", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/fatafatbetHistory"
                            ? "bg-[#945908] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            FataFat Bid Point History
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu(
                              "/app/declareResultFatafat",
                              handleNavbar()
                            )
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResultFatafat"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            FataFat Result Declare
                          </span>
                        </span>
                        <span
                          onClick={() =>
                            onClickMenu("/app/fatafat_bid_history", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/fatafat_bid_history"
                            ? "bg-[#945908] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            FataFat Bid History
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}


                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "game" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <LuGamepad2 size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#945908] w-[150px] lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("game")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <LuGamepad2 size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#945908] w-[150px] lg:font-lg">
                          Game Management
                        </span>
                        {showSection === "game" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    )}

                    {/* Submenu with transition */}
                    <div
                      className={`transition-all duration-500 ease-in-out overflow-hidden ${showSection === "game" ? "max-h-[500px]" : "max-h-0"
                        }`}
                    >
                      <div className="mb-5 flex flex-col pl-10">


                        <span
                          onClick={() => onClickMenu("/app/game", handleNavbar())}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/game"
                            ? "text-white bg-[#945908] rounded-md"
                            : "text-white bg-[black] rounded-md hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm lg:font-lg">Game</span>
                        </span>



                        <span
                          onClick={() => onClickMenu("/app/market", handleNavbar())}
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/market" ||
                            location.pathname.includes("app/EditMarket/")
                            ? "text-white bg-[#945908] rounded-md"
                            : "text-white bg-[black] rounded-md hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm lg:font-lg">Market Management</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}


                {localStoragedata && localStoragedata.roleId == 0 && (
                  <div className="cursor-pointer group">
                    {showSection === "resultDeclare" ? (
                      <span
                        onClick={() => setShowSection("")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white bg-[black] rounded-sm transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <TbTargetArrow size={20} />
                        </span>
                        <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection("resultDeclare")}
                        className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                      >
                        <span className="group-hover:text-[#945908]">
                          <TbTargetArrow size={20} />{" "}
                        </span>
                        <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                          Result Management
                        </span>
                        {showSection === "resultDeclare" ? (
                          <HiChevronUp className="group-hover:text-[#945908]" />
                        ) : (
                          <HiChevronDown className="group-hover:text-[#945908]" />
                        )}
                      </span>
                    )}
                    {showSection === "resultDeclare" ? (
                      <div className=" mb-5  flex flex-col pl-10">
                        <span
                          onClick={() =>
                            onClickMenu("/app/declareResult", handleNavbar())
                          }
                          className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/declareResult"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                            }`}
                        >
                          <MdKeyboardArrowRight />{" "}
                          <span className="text-sm  lg:font-lg">
                            Game Result Game
                          </span>
                        </span>

                      </div>
                    ) : null}
                  </div>
                )}

                <div className="cursor-pointer group">
                  {showSection === "wm" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#945908]">
                        <BiWalletAlt size={20} />
                      </span>
                      <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                        Wallet Management
                      </span>
                      {showSection === "wm" ? (
                        <HiChevronUp className="group-hover:text-[#945908]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#945908]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("wm")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#945908]">
                        <BiWalletAlt size={20} />{" "}
                      </span>
                      <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                        Wallet Management
                      </span>
                      {showSection === "wm" ? (
                        <HiChevronUp className="group-hover:text-[#945908]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#945908]" />
                      )}
                    </span>
                  )}
                  {showSection === "wm" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawRequest", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawRequest"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          WithDraw Request
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/AddFundRequest", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/AddFundRequest"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund Request
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/addFund", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFund"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund (UserWallet)
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawfund", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawfund"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          WithdrawFund  (UserWallet)
                        </span>
                      </span>


                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/ledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/ledger"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          My Point Statement
                        </span>
                      </span> */}

                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/betsledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">My Ledger</span>
                      </span> */}
                    </div>
                  ) : null}
                </div>
                <div className="cursor-pointer group">
                  {showSection === "report" ? (
                    <span
                      onClick={() => setShowSection("")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#945908]">
                        <TbReportAnalytics size={20} />
                      </span>
                      <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#945908]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#945908]" />
                      )}
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowSection("report")}
                      className="flex items-center px-1 py-2 space-x-4 text-sm text-white transition duration-150 ease-in-out cursor-pointer h-9"
                    >
                      <span className="group-hover:text-[#945908]">
                        <TbReportAnalytics size={20} />{" "}
                      </span>
                      <span className="text-sm group-hover:text-[#945908] w-[150px]  lg:font-lg">
                        Report Management
                      </span>
                      {showSection === "report" ? (
                        <HiChevronUp className="group-hover:text-[#945908]" />
                      ) : (
                        <HiChevronDown className="group-hover:text-[#945908]" />
                      )}
                    </span>
                  )}
                  {showSection === "report" ? (
                    <div className=" mb-5  flex flex-col pl-10">
                      <span
                        onClick={() =>
                          onClickMenu("/app/betHistory", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/betHistory"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Bid Point History
                        </span>
                      </span>


                      <span
                        onClick={() =>
                          onClickMenu("/app/userbidhistory", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/userbidhistory"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Bid History
                        </span>
                      </span>

                      <span
                        onClick={() =>
                          onClickMenu("/app/addFundReport", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/addFundReport"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          Add Fund Report
                        </span>
                      </span>
                      <span
                        onClick={() =>
                          onClickMenu("/app/withdrawReport", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${location.pathname === "/app/withdrawReport"
                          ? "bg-[#945908] text-white  rounded-lg "
                          : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                          }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          WithDraw Report
                        </span>
                      </span>

                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/ledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/ledger"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">
                          My Point Statement
                        </span>
                      </span> */}

                      {/* <span
                        onClick={() =>
                          onClickMenu("/app/betsledger", handleNavbar())
                        }
                        className={`cursor-pointer h-9 items-center flex space-x-4 px-1 py-2 text-sm transition ease-in-out duration-150    ${
                          location.pathname === "/app/betsledger"
                            ? "bg-[black] text-white  rounded-lg "
                            : "text-white bg-[black] rounded-md   hover:text-[#945908]"
                        }`}
                      >
                        <MdKeyboardArrowRight />{" "}
                        <span className="text-sm  lg:font-lg">My Ledger</span>
                      </span> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
