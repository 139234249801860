export const reportConstants = {

    GET_BETLIST_FOR_ADMIN_REQUEST: 'GET_BETLIST_FOR_ADMIN_REQUEST',
    GET_BETLIST_FOR_ADMIN_SUCCESS: 'GET_BETLIST_FOR_ADMIN_SUCCESS',
    GET_BETLIST_FOR_ADMIN_FAILURE: 'GET_BETLIST_FOR_ADMIN_FAILURE',

GET_WIN_REQUEST:"GET_WIN_REQUEST",
GET_WIN_SUCCESS:"GET_WIN_SUCCESS",
GET_WIN_FAILURE:"GET_WIN_FAILURE",


GETBET_WISE_LEDGER_DATA_REQUEST:"GETBET_WISE_LEDGER_DATA_REQUEST",
GETBET_WISE_LEDGER_DATA_SUCCESS:"GETBET_WISE_LEDGER_DATA_SUCCESS",
GETBET_WISE_LEDGER_DATA_FAILURE:"GETBET_WISE_LEDGER_DATA_FAILURE",


GET_NUMBER_WISE_MARKET_SUM_REQUEST:"GET_NUMBER_WISE_MARKET_SUM_REQUEST",
GET_NUMBER_WISE_MARKET_SUM_SUCCESS:"GET_NUMBER_WISE_MARKET_SUM_SUCCESS",
GET_NUMBER_WISE_MARKET_SUM_FAILURE:"GET_NUMBER_WISE_MARKET_SUM_FAILURE",

GET_ALL_ENTRIES_REQUEST:"GET_ALL_ENTRIES_REQUEST",
GET_ALL_ENTRIES_SUCCESS:"GET_ALL_ENTRIES_SUCCESS",
GET_ALL_ENTRIES_FAILURE:"GET_ALL_ENTRIES_FAILURE",

GET_DOWN_LINE_REQUEST:"GET_DOWN_LINE_REQUEST",
GET_DOWN_LINE_SUCCESS:"GET_DOWN_LINE_SUCCESS",
GET_DOWN_LINE_FAILURE:"GET_DOWN_LINE_FAILURE",

GET_LEDFERS_REQUEST:"GET_LEDFERS_REQUEST",
GET_LEDFERS_SUCCESS:"GET_LEDFERS_SUCCESS",
GET_LEDFERS_FAILURE:"GET_LEDFERS_FAILURE",
GET_LEDFERS_ID_REQUEST:"GET_LEDFERS_ID_REQUEST",
GET_LEDFERS_ID_SUCCESS:"GET_LEDFERS_ID_SUCCESS",
GET_LEDFERS_ID_FAILURE:"GET_LEDFERS_ID_FAILURE",


};
