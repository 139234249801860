import { reportConstants } from "../_constants";

export default function reports(state = {}, action) {
  switch (action.type) {
    case reportConstants.GETBET_WISE_LEDGER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GETBET_WISE_LEDGER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getBetWiseReport: action?.report?.data,
      };
    case reportConstants.GETBET_WISE_LEDGER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_LEDFERS_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case reportConstants.GET_LEDFERS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getLedgerTotals: action?.report?.data?.total,
        getLedgerLists: action?.report?.data?.list,
      };
    case reportConstants.GET_LEDFERS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_LEDFERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_LEDFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        crdt: action?.report?.data,
      };
    case reportConstants.GET_LEDFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_DOWN_LINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_DOWN_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        downLineData: action?.report?.data,
      };
    case reportConstants.GET_DOWN_LINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_ALL_ENTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_ALL_ENTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        getLedgerTotal: action?.report?.data?.total,
        getLedgerList: action?.report?.data?.list,
      };
    case reportConstants.GET_ALL_ENTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS:
      return {
        ...state,
        loading: false,
        numberListData: action?.report?.data,
      };
    case reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case reportConstants.GET_WIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_WIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        winList: action?.report?.data?.list,
        winTotal: action?.report?.data?.total,
        loading: false,
      };
    case reportConstants.GET_WIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        reportsList: action?.report?.data?.list,
        reportsTotal: action?.report?.data?.total,
        amountsPlaced: action?.report?.data?.amountsPlaced,
        totalWinAmount: action?.report?.data?.totalWinAmount,
        totalLossAmount: action?.report?.data?.totalLossAmount,
        loading: false,
      };
    case reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
